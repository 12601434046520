button.selected {
  border-style: inset;
}

.fadeIn {
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


.fadeOut {
  animation: fadeOut 1s ease-in forwards;
}

@keyframes fadeOut {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
