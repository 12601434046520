.Home {
  position: relative;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-areas:". . ."
                      ". board ."
                      ". . .";
}

.App-logo {
  /*grid-area: logo;*/
  align-self: center;
  justify-self: center;
  height: 7vw;
  min-height: 40px;
  /*left: 0vw;*/
  /*position: relative;*/
  display: grid;
}

.Board {
  display: grid;
  grid-area: board;
  background-color: black;
  grid-gap: 1vw;
  grid-template: repeat(3,1fr) / repeat(3,1fr);
  align-self: center;
  justify-self: center;
  /* max-width: 500px;
  max-height: 500px;
  min-width: 300px;
  min-height: 300px; */
  width: 38vw;
  height: 38vw;
  margin: auto;
  min-width: 350px;
  min-height: 350px;
}

.Board > button {
  position: relative;
  background-color: white;
  width: 12vw;
  height: 12vw;
  /* min-width: 75px;
  min-height: 75px;
  max-width: 160px;
  max-height: 160px; */
  display: grid;
  align-content: center;
  font-size: 4vw;
  /* margin: -1px; */
  border: none;
  padding: 0;
  min-width: 115px;
  min-height: 115px;
}

.Board > div {
  position: relative;
  background-color: white;
  /* width: 13vw; */
  /* height: 13vw; */
  min-width: 75px;
  min-height: 75px;
  /* max-width: 160px; */
  /* max-height: 160px; */
  display: grid;
  align-content: center;
  font-size: 4vw;
  /* margin: -1px; */
  border: none;
}


.round-star-label {
  position: absolute;
  right: -1vw;
  bottom: -1vw;
  display: grid;
  align-items: center;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 7vw;
  height: 7vw;
  max-width: 28px;
  max-height: 40px;
  border: none;
  font: normal 100%/normal Arial, Helvetica, sans-serif;
  color: rgba(0,0,0,1);
  text-align: center;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #61dafb;
  cursor: pointer;
}

.round-star-label .label-text {
  position: relative;
  z-index: 1;
  color: white;
  font-size: 1vmax;
  -webkit-transform: rotateZ(-30deg);
  transform: rotateZ(-30deg);
}

.round-star-label::before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 7vw;
  height: 7vw;
  max-width: 40px;
  max-height: 40px;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border: none;
  font: normal 100%/normal Arial, Helvetica, sans-serif;
  color: rgba(0,0,0,1);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #61dafb;
  text-shadow: none;
  -webkit-transform: rotateZ(30deg);
  transform: rotateZ(30deg);
}

.round-star-label::after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  width: 7vw;
  height: 7vw;
  max-width: 40px;
  max-height: 40px;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border: none;
  font: normal 100%/normal Arial, Helvetica, sans-serif;
  color: rgba(0,0,0,1);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #61dafb;
  text-shadow: none;
  -webkit-transform: rotateZ(60deg);
  transform: rotateZ(60deg);
}
