body {
  /*margin: 10px;*/
  /*padding: 10px;*/
}

body, input, button, textarea{
  font-family: 'Varela Round', sans-serif;
  font-size: 6vw;
}

button{
  cursor: pointer;
}
@media (min-width: 768px) {
  body{
    font-size: 4vw;
  }
  input, button, textarea{
    font-size: 3vw;
  }
}

.baloo > button {
  font-family: 'Baloo', cursive;
  font-size: 10vw !important;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.pulse {
	animation: pulse-anim 0.75s infinite alternate;
}

@keyframes pulse-anim {
	0% {
		transform: scale3d(1, 1, 1);
	}
	25%, 50% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		transform: scale3d(1.2, 1.2, 1);
	}
}
