.Game {
  position: relative;
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
  /*grid-template-areas:". . ."
                      ". gameDisplay ."
                      ". . .";*/
  /*grid-template-areas:". . . ."
                      ". myVideo theirVideo ."
                      ". board board ."
                      ". board board ."
                      ". . . .";*/
  grid-template-areas:". . . ."
                      ". VideoChat VideoChat ."
                      ". gameDisplay gameDisplay ."
                      ". gameDisplay gameDisplay ."
                      ". . . .";

}

#gameDisplay {
  grid-area: gameDisplay;
}

.VideoChat {
  grid-area: VideoChat;
  width:100%;
}

.error {
  color: red;
}

#share-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#share-container textarea {
  height: 36px;
}


@media (min-width: 768px) {
  .Game {
    position: relative;
    width: 100%;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-areas:". . . . ."
                        ". VideoChat gameDisplay gameDisplay ."
                        ". VideoChat gameDisplay gameDisplay ."
                        ". . . . .";
  }

}
