.VideoChat {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-areas:"myVideo theirVideo";
}

#myVideo {
  grid-area: myVideo;
  background-color: black;
  width: 50vw;
  height: 37.5vw;
  max-width: 320px;
  max-height: 240px;
  /*padding:5px;*/
}

#myCanvas {
  grid-area: myVideo;
  width: 50vw;
  height: 37.5vw;
  max-width: 320px;
  max-height: 240px;
  z-index:5;
}

#theirVideo {
  grid-area: theirVideo;
  background-color: black;
  width: 50vw;
  height: 37.5vw;
  max-width: 320px;
  max-height: 240px;
}

video {
  max-width: 320px;
  max-height: 240px;
  width: 100%;
}

#publisher {
  grid-area: myVideo;
  background-color: black;
  width: 50vw;
  height: 37.5vw;
  max-width: 320px;
  max-height: 240px;
  /*padding:5px;*/
}

#subscriber {
  grid-area: theirVideo;
  background-color: black;
  width: 50vw;
  height: 37.5vw;
  max-width: 320px;
  max-height: 240px;
}

@media (min-width: 768px) {
  .VideoChat{
    grid-template-areas:"myVideo"
                        "theirVideo";
  }
}
